// extracted by mini-css-extract-plugin
export var sectionClass = "TulipsTributesContactForm-module--sectionClass--xvi3H";
export var successSectionClass = "TulipsTributesContactForm-module--successSectionClass--qfiu6";
export var rowClass = "TulipsTributesContactForm-module--rowClass--BzBJW";
export var formControlClass = "TulipsTributesContactForm-module--formControlClass--Uc3ti";
export var checkboxGroupClass = "TulipsTributesContactForm-module--checkboxGroupClass--SMwiU";
export var checkboxContainerClass = "TulipsTributesContactForm-module--checkboxContainerClass--cvC7K";
export var checkmarkClass = "TulipsTributesContactForm-module--checkmarkClass--KER1H";
export var sendBtnClass = "TulipsTributesContactForm-module--sendBtnClass--15fUk";
export var successContainerClass = "TulipsTributesContactForm-module--successContainerClass--6eamb";
export var successMsgClass = "TulipsTributesContactForm-module--successMsgClass--Yj2an";